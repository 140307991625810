import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { defineComponent, reactive, ref, getCurrentInstance, onMounted, nextTick } from "vue";
import SvgIcon from "../components/SvgIcon.vue";
import FolderCard from "@/components/FolderCard.vue";
import { useRouter } from "vue-router";
import util from "@/util/util";
import { genFileId } from "element-plus";
import QrcodeVue from "qrcode.vue";
import html2canvas from "html2canvas";
import permission from "@/util/permission";
import { ElLoading } from "element-plus";
import cookies from "js-cookie";

import { ElNotification, ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
export default defineComponent({
  name: "ActivityDetail",
  components: {
    SvgIcon,
    FolderCard,
    QrcodeVue
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRouter();
    const QRcodeVisible = ref(false);
    const QRcodeBox = ref(null);
    const QRcodeMsg = reactive({});
    const activityId = route.currentRoute.value.params.activityId;
    const tokenData = util.getToken();
    const currentProtocol = window.location.protocol;
    const currentDomain = window.location.hostname;
    const currentPort = window.location.port;
    let currentUrl = currentProtocol + "//" + currentDomain;
    if (currentPort) {
      currentUrl += ":" + currentPort;
    }
    const jumpUrl = ref(currentUrl + "/install-info/");
    // const jumpUrl = ref("https://servicechannel.online/install-info/");
    // const jumpUrl = ref("http://192.168.60.248:8080/install-info/")
    const activity = reactive({
      activityImportMessageVisible: false,
      activityImportMessages: [],
      activityStat: {},
      activityData: [],
      organizationUserData: [],
      activityImportUploaded: {}
    });
    const wxCodeImage = ref(null);
    const getActivityImportUploaded = async activityId => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.getActivityImportUploaded(activityId, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getOrganizationUser = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.organizationRequest.getOrganizationUser(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getActivityStat = async activityId => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.getActivityStat(activityId, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getActivity = async activityId => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.getActivity(activityId, true).then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "202154") {
              resolve(res.result.data);
            } else {
              ElNotification({
                title: "失败",
                message: res.result.promptMsg,
                type: "error",
                duration: 6000
              });
              resolve(null);
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.msg,
              type: "error",
              duration: 6000
            });
            resolve(null);
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const removeFile = uploaded => {
      ElMessageBox.confirm("是否要删除文件" + uploaded.fileName, "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let activityDeleteUploadedRO = {
          activityId: activityId,
          uploadedId: uploaded.uploadedId
        };
        deleteUploadedFileRequest(activityDeleteUploadedRO, uploaded);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消删除"
        });
      });
    };
    onMounted(async () => {
      let organizationUserList = await getOrganizationUser();
      if (organizationUserList == null) {
        return;
      }
      activity.organizationUserData.push(...organizationUserList);
      loadActivityData();
    });
    const loadActivityData = async () => {
      if (activityId != null && activityId != "") {
        if (tokenData.type == 2) {
          let activityImportUploadedList = await getActivityImportUploaded(activityId);
          if (Array.isArray(activityImportUploadedList)) {
            for (let uploaded of activityImportUploadedList) {
              activity.activityImportUploaded[uploaded.type] = uploaded;
            }
          }
        }
        activity.activityStat = await getActivityStat(activityId);
        if (activity.activityStat != null && activity.activityStat.activityStoreTypeQuantity != null) {
          let totalQuantity = 0;
          for (let activityStoreTypeQuantity of activity.activityStat.activityStoreTypeQuantity) {
            totalQuantity += activityStoreTypeQuantity.quantity;
          }
          activity.activityStat.totalQuantity = totalQuantity;
        }
        activity.activityData = await getActivity(activityId);
        activity.activityData.activityBegin = activity.activityData.activityBegin.replaceAll("-", ".");
        activity.activityData.activityEnd = activity.activityData.activityEnd.replaceAll("-", ".");
        for (let uploaded of activity.activityData.uploaded) {
          uploaded.date = formatCurrentDate(new Date(uploaded.uploadedTime));
        }
        let managers = {};
        activity.activityManagers = [];
        if (activity.activityData.activityManagers != null) {
          for (let activityManagers of activity.activityData.activityManagers) {
            let findIndex = activity.organizationUserData.findIndex(element => element.organizationId == activityManagers.organizationId);
            if (findIndex != -1) {
              let organization = activity.organizationUserData[findIndex];
              if (managers[organization.organizationId] == null) {
                let organizationManagers = {
                  organizationId: organization.organizationId,
                  organizationName: organization.organizationName,
                  managers: [{
                    managedArea: activityManagers.managedArea,
                    lastName: activityManagers.lastName,
                    firstName: activityManagers.firstName,
                    mobile: activityManagers.mobile,
                    email: activityManagers.email
                  }]
                };
                activity.activityManagers.push(organizationManagers);
                managers[organization.organizationId] = organizationManagers;
              } else {
                managers[organization.organizationId].managers.push({
                  managedArea: activityManagers.managedArea,
                  lastName: activityManagers.lastName,
                  firstName: activityManagers.firstName,
                  mobile: activityManagers.mobile,
                  email: activityManagers.email
                });
              }
            }
          }
        }
      }
    };
    const formatCurrentDate = date => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}.${month}.${day}`;
    };
    const getSignature = async directory => {
      return new Promise((resolve, reject) => {
        proxy.$api.authorizationRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
    };
    const uploadStoreLocation = ref(null);
    const uploadStoreLocationPopoverVisible = ref(false);
    const uploadStoreLocationData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const openUploadStoreLocationPopover = () => {
      uploadStoreLocation.value.clearFiles();
      uploadStoreLocationPopoverVisible.value = true;
    };
    const handleStoreLocationBeforeUpload = async file => {
      uploadStoreLocationData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadStoreLocationData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadStoreLocationData.signatureData.dir + fullName;
      uploadStoreLocationData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadStoreLocationData.signatureData.policy,
        OSSAccessKeyId: uploadStoreLocationData.signatureData.accessId,
        signature: uploadStoreLocationData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadStoreLocationData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const handleStoreLocationExceed = files => {
      uploadStoreLocation.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadStoreLocation.value.handleStart(file);
      uploadStoreLocation.value.submit();
    };
    const handleStoreLocationOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let activityUploadedRO = {
          activityId: activityId,
          uploadedRO: {
            fileName: uploadStoreLocationData.data.name,
            uploadedName: uploadStoreLocationData.data.key
          }
        };
        importStoreLocationRequest(activityUploadedRO);
      }
    };
    const importStoreLocationRequest = async activityUploadedRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "上传成功，正在导入活动店铺清单",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.activityRequest.importStoreLocation(activityUploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202157") {
            uploadStoreLocationPopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            loadActivityData();
          } else if (res.result.dataCode === "202170") {
            uploadStoreLocationPopoverVisible.value = false;
            activity.activityImportMessageVisible = true;
            activity.activityImportMessages = res.result.data;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const exportActivityStoreLocationClick = () => {
      getStoreLocationExport(activityId);
    };
    const getStoreLocationExport = activityId => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "正在导出模板",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.activityRequest.getStoreLocationExport(activityId).then(res => {
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const exportActivityInstallationClick = () => {
      getInstallationExport(activityId);
    };
    const getInstallationExport = activityId => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "正在导出模板",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.activityRequest.getInstallationExport(activityId).then(res => {
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const uploadActivityInstallation = ref(null);
    const uploadActivityInstallationPopoverVisible = ref(false);
    const uploadActivityInstallationData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const openUploadActivityInstallationPopover = () => {
      uploadActivityInstallation.value.clearFiles();
      uploadActivityInstallationPopoverVisible.value = true;
    };
    const handleActivityInstallationBeforeUpload = async file => {
      uploadActivityInstallationData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadActivityInstallationData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadActivityInstallationData.signatureData.dir + fullName;
      uploadActivityInstallationData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadActivityInstallationData.signatureData.policy,
        OSSAccessKeyId: uploadActivityInstallationData.signatureData.accessId,
        signature: uploadActivityInstallationData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadActivityInstallationData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const handleActivityInstallationExceed = files => {
      uploadActivityInstallation.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadActivityInstallation.value.handleStart(file);
      uploadActivityInstallation.value.submit();
    };
    const handleActivityInstallationOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let activityUploadedRO = {
          activityId: activityId,
          uploadedRO: {
            fileName: uploadActivityInstallationData.data.name,
            uploadedName: uploadActivityInstallationData.data.key
          }
        };
        importActivityInstallationRequest(activityUploadedRO);
      }
    };
    const importActivityInstallationRequest = async activityUploadedRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "上传成功，正在导入供应商负责店铺清单",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.activityRequest.importActivityInstallation(activityUploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202160") {
            uploadActivityInstallationPopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            loadActivityData();
          } else if (res.result.dataCode === "202176") {
            uploadActivityInstallationPopoverVisible.value = false;
            activity.activityImportMessageVisible = true;
            activity.activityImportMessages = res.result.data;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    //时间戳转时间
    const getDate = (data, notime) => {
      let date = new Date(data); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      if (notime) {
        return Y + M + D;
      } else {
        return Y + M + D + h + m + s;
      }
    };
    const activityEditClick = () => {
      route.push({
        path: "/activity-create/" + activityId
      });
    };
    const uploadInstallation = ref(null);
    const uploadInstallationPopoverVisible = ref(false);
    const uploadInstallationData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: [],
      syncDate: null
    });
    const openUploadInstallationPopover = () => {
      uploadInstallation.value.clearFiles();
      uploadInstallationPopoverVisible.value = true;
    };
    const handleInstallationBeforeUpload = async file => {
      uploadInstallationData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadInstallationData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadInstallationData.signatureData.dir + fullName;
      uploadInstallationData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadInstallationData.signatureData.policy,
        OSSAccessKeyId: uploadInstallationData.signatureData.accessId,
        signature: uploadInstallationData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadInstallationData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const handleInstallationExceed = files => {
      uploadInstallation.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadInstallation.value.handleStart(file);
      uploadInstallation.value.submit();
    };
    const handleInstallationOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let activityUploadedRO = {
          activityId: activityId,
          uploadedRO: {
            fileName: uploadInstallationData.data.name,
            uploadedName: uploadInstallationData.data.key
          }
        };
        importInstallationRequest(activityUploadedRO);
      }
    };
    const importInstallationRequest = async activityUploadedRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "上传成功，正在导入批量安装预约",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.activityRequest.importInstallation(activityUploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202163") {
            uploadInstallationPopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            loadActivityData();
          } else if (res.result.dataCode === "202177") {
            uploadInstallationPopoverVisible.value = false;
            activity.activityImportMessageVisible = true;
            activity.activityImportMessages = res.result.data;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const uploadFile = ref(null);
    const uploadFilePopoverVisible = ref(false);
    const uploadFileData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const openUploadFilePopover = () => {
      uploadFile.value.clearFiles();
      uploadFilePopoverVisible.value = true;
    };
    const handleFileBeforeUpload = async file => {
      uploadFileData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadFileData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadFileData.signatureData.dir + fullName;
      uploadFileData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadFileData.signatureData.policy,
        OSSAccessKeyId: uploadFileData.signatureData.accessId,
        signature: uploadFileData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadFileData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const handleFileExceed = files => {
      uploadFile.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadFile.value.handleStart(file);
      uploadFile.value.submit();
    };
    const handleFileOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let activityUploadedRO = {
          activityId: activityId,
          uploadedRO: {
            fileName: uploadFileData.data.name,
            uploadedName: uploadFileData.data.key
          }
        };
        uploadFileRequest(activityUploadedRO);
      }
    };
    const uploadFileRequest = async activityUploadedRO => {
      proxy.$api.activityRequest.activityUploadFile(activityUploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202165") {
            let uploaded = res.result.data;
            uploaded.date = formatCurrentDate(new Date(uploaded.uploadedTime));
            activity.activityData.uploaded.push(uploaded);
            uploadFilePopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const deleteUploadedFileRequest = async (activityDeleteUploadedRO, uploaded) => {
      proxy.$api.activityRequest.deleteUploadFile(activityDeleteUploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202167") {
            activity.activityData.uploaded = activity.activityData.uploaded.filter(obj => obj !== uploaded);
            uploadFilePopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const siteCodeClick = row => {
      getInstallationWxCode(row.vendorId);
      // QRcodeVisible.value = true;
      // console.log(row);
      // QRcodeMsg.vendorName = row.vendorName;
      // jumpUrl.value = currentUrl + "/install-info/";
      // jumpUrl.value =
      //   jumpUrl.value + activity.activityData.activityId + "/" + row.vendorId;
      // console.log(jumpUrl.value);
    };

    const progressColor = ["#FF3C66", "#20D5B1", "#FFB742", "#429BFF"];
    const progressText = ["发货进度", "送达进度", "安装进度", "安装确认"];
    const storeDetailClick = () => {
      route.push({
        path: "/store-manage/" + activityId
      });
    };
    const storeTypeClick = storeTypeId => {
      route.push({
        path: "/store-manage/" + activityId + "/" + storeTypeId
      });
    };
    const installClick = stat => {
      let convey = stat[1];
      let install = stat[3];
      cookies.set("installConvey_" + activityId, JSON.stringify({
        convey: convey,
        install: install
      }), {
        expires: 30
      });
      route.push({
        path: "/install-convey/" + activityId
      });
    };
    const installVendorClick = (vendorId, stat) => {
      let convey = stat[1];
      let install = stat[3];
      cookies.set("installConvey_" + activityId, JSON.stringify({
        convey: convey,
        install: install
      }), {
        expires: 30
      });
      route.push({
        path: "/install-convey/" + activityId + "/" + vendorId
      });
    };

    //二维码复制
    const copyQrcode = () => {
      html2canvas(QRcodeBox.value, {
        useCORS: true,
        // 【重要】开启跨域配置
        allowTaint: true // 允许跨域图片
      }).then(canvas => {
        canvas.toBlob(blob => {
          const data = [new ClipboardItem({
            [blob.type]: blob
          })];
          navigator.clipboard.write(data).then(() => {
            console.log("成功");
            ElNotification({
              title: "成功",
              message: "已复制到剪切板",
              type: "success",
              duration: 3000
            });
          }, () => {
            console.log("失败");
            ElNotification({
              title: "失败",
              message: "复制失败，此浏览器不支持",
              type: "error",
              duration: 3000
            });
          });
        }, "image/png");
      });
    };
    const exportLogisticsExportClick = () => {
      getLogisticsExport(activityId);
    };
    const getLogisticsExport = activityId => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "正在导出模板",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.scheduleRequest.getLogisticsExport(activityId).then(res => {
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const uploadConverPane = ref(null);
    const uploadConverData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const uploadConverPopoverVisible = ref(false);
    const openUploadConverPopover = () => {
      uploadConverPane.value[0].clearFiles();
      uploadConverPopoverVisible.value = true;
    };
    const handleConverExceed = files => {
      uploadConverPane.value[0].clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadConverPane.value[0].handleStart(file);
      uploadConverPane.value[0].submit();
    };
    const handleConverBeforeUpload = async file => {
      uploadConverData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadConverData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadConverData.signatureData.dir + fullName;
      uploadConverData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadConverData.signatureData.policy,
        OSSAccessKeyId: uploadConverData.signatureData.accessId,
        signature: uploadConverData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadConverData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const handleConverOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        const activityUploadedRO = {
          activityId: activityId,
          uploadedRO: {
            fileName: uploadConverData.data.name,
            uploadedName: uploadConverData.data.key
          }
        };
        importConverRequest(activityUploadedRO);
      }
    };
    const importConverRequest = async activityUploadedRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "上传成功，正在导入物流表",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.scheduleRequest.importLogistics(activityUploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202261") {
            uploadConverPopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            loadActivityData();
          } else if (res.result.dataCode === "202266") {
            uploadConverPopoverVisible.value = false;
            activity.activityImportMessageVisible = true;
            activity.activityImportMessages = res.result.data;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const dsyncInstallationDateClick = () => {
      dsyncInstallationDateRequest(activityId);
    };
    const dsyncInstallationDateRequest = async activityId => {
      proxy.$api.activityRequest.syncInstallationDate(activityId).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202183") {
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            loadActivityData();
          } else if (res.result.dataCode === "202182") {
            uploadConverPopoverVisible.value = false;
            activity.activityImportMessageVisible = true;
            activity.activityImportMessages = res.result.data;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const downloadFile = (url, fileName) => {
      fetch(url).then(response => response.blob()).then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      });
    };
    const getInstallationWxCode = vendorId => {
      let installationWxCodeRO = {
        activityId: activityId,
        vendorId: vendorId
      };
      proxy.$api.wxRequest.getInstallationWxCode(installationWxCodeRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "200000") {
            wxCodeImage.value = res.result.data;
            QRcodeVisible.value = true;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    return {
      tokenData,
      permission,
      activity,
      removeFile,
      openUploadStoreLocationPopover,
      uploadStoreLocation,
      uploadStoreLocationPopoverVisible,
      uploadStoreLocationData,
      handleStoreLocationExceed,
      handleStoreLocationBeforeUpload,
      handleStoreLocationOnSuccess,
      exportActivityStoreLocationClick,
      openUploadActivityInstallationPopover,
      uploadActivityInstallation,
      uploadActivityInstallationPopoverVisible,
      uploadActivityInstallationData,
      handleActivityInstallationExceed,
      handleActivityInstallationBeforeUpload,
      handleActivityInstallationOnSuccess,
      activityEditClick,
      exportActivityInstallationClick,
      openUploadInstallationPopover,
      uploadInstallation,
      uploadInstallationPopoverVisible,
      uploadInstallationData,
      handleInstallationExceed,
      handleInstallationBeforeUpload,
      handleInstallationOnSuccess,
      openUploadFilePopover,
      uploadFile,
      uploadFilePopoverVisible,
      uploadFileData,
      handleFileExceed,
      handleFileBeforeUpload,
      handleFileOnSuccess,
      siteCodeClick,
      progressColor,
      progressText,
      storeDetailClick,
      storeTypeClick,
      installClick,
      installVendorClick,
      QRcodeVisible,
      QRcodeMsg,
      jumpUrl,
      copyQrcode,
      getDate,
      QRcodeBox,
      exportLogisticsExportClick,
      uploadConverPane,
      uploadConverPopoverVisible,
      uploadConverData,
      openUploadConverPopover,
      handleConverExceed,
      handleConverBeforeUpload,
      handleConverOnSuccess,
      dsyncInstallationDateClick,
      downloadFile,
      wxCodeImage,
      getInstallationWxCode
    };
  }
});