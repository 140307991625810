import "core-js/modules/es.array.push.js";
import { reactive, ref, getCurrentInstance, onMounted, nextTick } from "vue";
import SvgIcon from "../components/SvgIcon.vue";
import util from "@/util/util";
import { genFileId, ElLoading } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import useClipboard from "vue-clipboard3";
import permission from "@/util/permission";
import store from "../store";
import cookies from "js-cookie";

import { ElNotification, ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: "InstallPlan",
  components: {
    SvgIcon
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const {
      toClipboard
    } = useClipboard();
    const router = useRouter();
    const route = useRoute();
    const activityId = route.params.activityId;
    const vendorId = route.params.vendorId;
    const tokenData = util.getToken();
    let installConveyData = {};
    try {
      installConveyData = JSON.parse(cookies.get("installConvey_" + activityId));
    } catch {}
    const importMessage = reactive({
      importMessageVisible: false,
      importMessages: []
    });
    const deliveredControl = reactive({
      allChecked: false,
      isOpenBatch: false
    });
    const activeName = ref("first");
    const uploadInstallPane = ref(null);
    const installTable = ref();
    // 分页
    const installPaginationData = reactive({
      pageSizes: [25, 50, 100],
      currentPage: 1,
      totalSize: 0,
      pageSize: 25
    });

    // 安装进度列表
    const cityData = reactive([]);
    const provinceData = reactive([]);
    const activityData = reactive([]);
    const storeTypeData = reactive([]);
    const vendorData = reactive([]);
    const vendorTypeData = reactive([]);
    const installationStatusData = reactive([]);
    const getInstallationConfirmData = reactive([]);
    const installationData = reactive({
      data: []
    });
    const uploadInstallData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const uploadInstallPopoverVisible = ref(false);
    const installQuery = reactive({
      typeIndex: 1,
      insValue: "",
      insSelect: {
        storeType: "",
        vendorId: "",
        vendorTypeId: "",
        installationStatusId: "",
        installationResultId: "",
        areaInput: "",
        installationConfirmStatusId: "",
        activityId: null
      }
    });
    //分页Select变化
    const paginationSizeInstallChange = val => {
      let data = {
        cityName: installQuery.insSelect.areaInput,
        installationResultId: installQuery.insSelect.installationResultId,
        installationStatusId: installQuery.insSelect.installationStatusId,
        searchKeyword: installQuery.insValue,
        storeType: installQuery.insSelect.storeType,
        vendorId: installQuery.insSelect.vendorId,
        activityId: installQuery.insSelect.activityId,
        vendorTypeId: installQuery.insSelect.vendorTypeId
      };
      getInstallationPageApi(data, installPaginationData.currentPage, installPaginationData.pageSize);
    };
    //分页页数变化
    const paginationCurrentInstallChange = val => {
      let data = {
        cityName: installQuery.insSelect.areaInput,
        installationResultId: installQuery.insSelect.installationResultId,
        installationStatusId: installQuery.insSelect.installationStatusId,
        searchKeyword: installQuery.insValue,
        storeType: installQuery.insSelect.storeType,
        vendorId: installQuery.insSelect.vendorId,
        activityId: installQuery.insSelect.activityId,
        vendorTypeId: installQuery.insSelect.vendorTypeId
      };
      getInstallationPageApi(data, installPaginationData.currentPage, installPaginationData.pageSize);
    };
    //btn点击事件
    const openUploadInstallPopover = () => {
      uploadInstallPane.value.clearFiles();
      uploadInstallPopoverVisible.value = true;
    };
    //当超出限制时，执行的钩子函数
    const handleInstallExceed = files => {
      uploadInstallPane.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadInstallPane.value.handleStart(file);
      uploadInstallPane.value.submit();
    };
    //获取文件签名等信息
    const getSignature = async directory => {
      console.log(directory);
      return new Promise((resolve, reject) => {
        proxy.$api.authorizationRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
    };
    //上传文件之前的钩子
    const handleInstallBeforeUpload = async file => {
      uploadInstallData.signatureData = await getSignature("excel/temp");
      let filename = file.name;
      let fullName = uploadInstallData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadInstallData.signatureData.dir + fullName;
      uploadInstallData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadInstallData.signatureData.policy,
        OSSAccessKeyId: uploadInstallData.signatureData.accessId,
        signature: uploadInstallData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadInstallData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    //文件上传成功的钩子
    const handleInstallOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let activityUploadedRO = {
          activityId: activityId,
          uploadedRO: {
            fileName: uploadInstallData.data.name,
            uploadedName: uploadInstallData.data.key
          }
        };
        importInstallationRequest(activityUploadedRO);
      }
    };
    const importInstallationRequest = async activityUploadedRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "上传成功，正在导入批量安装预约",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.activityRequest.importInstallation(activityUploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202163") {
            uploadInstallPopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else if (res.result.dataCode === "202177") {
            uploadInstallPopoverVisible.value = false;
            importMessage.importMessageVisible = true;
            importMessage.importMessages = res.result.data;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const installLoading = ref(false);
    //时间戳转时间
    const getDate = data => {
      let date = new Date(data); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    };
    //表头排序
    const headerCellInstallClickEvent = ({
      column,
      triggerResizable,
      triggerSort,
      triggerFilter
    }) => {
      const $table = installTable.value;
      if ($table) {
        // 如果触发了列的其他功能按钮
        if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
          if (column.order === "desc") {
            $table.clearSort();
          } else if (column.order === "asc") {
            $table.sort(column.field, "desc");
          } else {
            $table.sort(column.field, "asc");
          }
        }
      }
    };
    //获取安装进度列表
    const getInstallationPageApi = async (data, pageIndex, pageSize, add) => {
      installLoading.value = true;
      let logisticsPageRO = {
        data: data,
        pageIndex: pageIndex,
        pageSize: pageSize
      };
      let callApi = proxy.$api.scheduleRequest.getInstallationPage;
      if (vendorId == "" && tokenData.type === 1) {
        callApi = proxy.$api.scheduleRequest.getStoreInstallationPage;
      }
      callApi(logisticsPageRO).then(async res => {
        console.log(res);
        if (res.code === "200000") {
          if (res.result.dataCode === "202250") {
            let msg = res.result.data.pageList;
            if (msg) {
              for (let install of msg) {
                const uniqueStoreLocations = install.storeLocations.reduce((accumulator, current) => {
                  const existingObject = accumulator.find(item => item.installationResponsibleName === current.installationResponsibleName);
                  if (!existingObject) {
                    accumulator.push(current);
                  }
                  return accumulator;
                }, []);
                install.activityInstallations = uniqueStoreLocations;
              }
              if (add) {
                installationData.data.push(...msg);
                installPaginationData.totalSize = res.result.data.totalCount;
              } else {
                installationData.data = msg;
                installPaginationData.totalSize = res.result.data.totalCount;
              }
            } else {
              installationData.data = msg;
              installPaginationData.totalSize = res.result.data.totalCount;
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        installLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        installLoading.value = false;
      });
    };
    const openInstallationEvent = () => {
      let installationPageRO = {
        cityName: installQuery.insSelect.areaInput,
        installationResultId: installQuery.insSelect.installationResultId,
        installationStatusId: installQuery.insSelect.installationStatusId,
        searchKeyword: installQuery.insValue,
        activityId: installQuery.insSelect.activityId,
        storeType: installQuery.insSelect.storeType,
        vendorId: installQuery.insSelect.vendorId,
        vendorTypeId: installQuery.insSelect.vendorTypeId
      };
      getInstallationExport(installationPageRO);
    };
    const getInstallationExport = installationPageRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "正在导出模板",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.scheduleRequest.getInstallationExport(installationPageRO).then(res => {
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    // 获取所有店铺城市
    const getStoreCity = async activityId => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreCity(activityId, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有店铺省份
    const getStoreProvince = async activityId => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreProvince(activityId, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有店铺类型
    const getStoreType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreType(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有供应商
    const getVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有供应商类型
    const getVendorType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorType(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 获取所有安装状态
    const getInstallationStatus = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.scheduleRequest.getInstallationStatus(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 获取所有验收状态
    const getInstallationConfirm = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getInstallationResult(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const selectAllActivity = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.selectAllActivity(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    //搜索点击事件
    const searchInstallClick = () => {
      let data = {
        cityName: installQuery.insSelect.areaInput,
        installationResultId: installQuery.insSelect.installationResultId,
        installationStatusId: installQuery.insSelect.installationStatusId,
        searchKeyword: installQuery.insValue,
        activityId: installQuery.insSelect.activityId,
        storeType: installQuery.insSelect.storeType,
        vendorId: installQuery.insSelect.vendorId,
        vendorTypeId: installQuery.insSelect.vendorTypeId,
        installationConfirmStatusId: installQuery.insSelect.installationConfirmStatusId
      };
      getInstallationPageApi(data, 1, installPaginationData.pageSize);
    };
    // 店铺信息复制
    const copyStore = async row => {
      let text = "地址：" + row.storeAddress + "\n" + "联系人：" + row.storeContactName + "\n" + "电话：" + row.storeContactMobile;
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        console.log("Success");
        ElNotification({
          title: "成功",
          message: "复制到剪切板",
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };
    // 供应商信息复制
    const copyVendor = async row => {
      let text = "";
      if (Array.isArray(row.activityInstallations)) {
        for (let install of row.activityInstallations) {
          text += "联系人：" + install.installationResponsibleName + "\n" + "电话：" + row.phoneNumber;
        }
      }
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        console.log("Success");
        ElNotification({
          title: "成功",
          message: "复制到剪切板",
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };
    // 验收信息复制
    const copyResult = async row => {
      let text = "联系人：" + row.installationResultContactName + "\n" + "电话：" + row.installationResultContactMobile;
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        console.log("Success");
        ElNotification({
          title: "成功",
          message: "复制到剪切板",
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };
    const installTableScroll = ({
      type,
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      bodyWidth,
      bodyHeight,
      isX,
      isY,
      $event
    }) => {
      let mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches) {
        if (scrollTop + bodyHeight >= scrollHeight - 20) {
          let totalCount = Math.ceil(installPaginationData.totalSize / installPaginationData.pageSize);
          console.log("-------------------" + installPaginationData.currentPage < totalCount);
          if (installPaginationData.currentPage < totalCount) {
            console.log("翻页");
            installPaginationData.currentPage++;
            let data = {
              cityName: installQuery.insSelect.areaInput,
              installationResultId: installQuery.insSelect.installationResultId,
              installationStatusId: installQuery.insSelect.installationStatusId,
              searchKeyword: installQuery.insValue,
              storeType: installQuery.insSelect.storeType,
              vendorId: installQuery.insSelect.vendorId,
              vendorTypeId: installQuery.insSelect.vendorTypeId
            };
            getInstallationPageApi(data, installPaginationData.currentPage, installPaginationData.pageSize, "add");
          }
        }
      }
    };
    const goConveyPlan = () => {
      if (vendorId) {
        router.push({
          path: "/convey-plan/" + activityId + "/" + vendorId
        });
      } else {
        router.push({
          path: "/convey-plan/" + activityId
        });
      }
    };
    onMounted(async () => {
      let windowWidth = window.innerWidth;
      console.log(windowWidth);
      if (windowWidth <= 768) {
        installPaginationData.pageSize = 20;
        conveyPaginationData.pageSize = 20;
      }
      if (vendorId) {
        installQuery.insSelect.vendorId = parseInt(vendorId);
        conveyQuery.insSelect.vendorId = parseInt(vendorId);
      }
      if (activityId) {
        installQuery.insSelect.activityId = parseInt(activityId);
        conveyQuery.insSelect.activityId = parseInt(activityId);
      }
      getInstallationPageApi({
        vendorId: installQuery.insSelect.vendorId,
        activityId: installQuery.insSelect.activityId
      }, installPaginationData.currentPage, installPaginationData.pageSize);
      getLogisticsPageApi({
        searchKeyword: conveyQuery.insValue,
        activityId: conveyQuery.insSelect.activityId,
        storeTypeId: conveyQuery.insSelect.storeType,
        vendorId: conveyQuery.insSelect.vendorId,
        city: conveyQuery.insSelect.areaInput,
        logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
        expressStateId: conveyQuery.insSelect.state
      }, conveyPaginationData.currentPage, conveyPaginationData.pageSize);
      // 获取活动相关所有店铺
      let cityList = await getStoreCity(activityId);
      if (cityList == null) {
        return;
      }
      cityData.push(...cityList);
      // 获取活动相关所有省份
      // let provinceList = await getStoreProvince(activityId);
      // if (provinceList == null) {
      //   return;
      // }
      // provinceData.push(...provinceList);
      // 获取所有活动
      let activityList = await selectAllActivity();
      if (activityList == null) {
        return;
      }
      let findIndex = activityList.findIndex(element => element.activityId == activityId);
      if (findIndex != -1) {
        store.commit("setHeaderTitle", activityList[findIndex].activityName);
      }
      activityData.push(...activityList);
      // 获取所有店铺类型
      let storeTypeList = await getStoreType();
      if (storeTypeList == null) {
        return;
      }
      storeTypeData.push(...storeTypeList);
      // 获取所有供应商
      let vendorList = await getVendor();
      if (vendorList == null) {
        return;
      }
      vendorData.push(...vendorList);
      // 获取所有供应商类型
      let vendorTypeList = await getVendorType();
      if (vendorTypeList == null) {
        return;
      }
      vendorTypeData.push(...vendorTypeList);
      // 获取所有安装状态
      let installationStatusList = await getInstallationStatus();
      if (installationStatusList == null) {
        return;
      }
      installationStatusData.push(...installationStatusList);
      // 获取所有验收状态
      let getInstallationConfirmList = await getInstallationConfirm();
      if (getInstallationConfirmList == null) {
        return;
      }
      getInstallationConfirmData.push(...getInstallationConfirmList);
      // 获取所有物流状态
      let getExpressStateList = await getExpressState();
      if (getExpressStateList == null) {
        return;
      }
      for (let i = 0; i < getExpressStateList.length; i++) {
        if (parseInt(getExpressStateList[i].expressStateId) <= 10) {
          expressStateListData.push(getExpressStateList[i]);
        }
      }
      // expressStateListData.push(...getExpressStateList);
      // 获取所有物流类型
      let getLogisticsTypeList = await getLogisticsType();
      if (getLogisticsTypeList == null) {
        return;
      }
      logisticsTypeData.push(...getLogisticsTypeList);
    });
    const expandConfig = {
      visibleMethod({
        row
      }) {
        if (row.storeLocations.length == 0) {
          return false;
        }
        return true;
      }
    };
    const installUploadClick = row => {
      if (vendorId == "" && tokenData.type === 1) {
        router.push({
          path: "/store-location/" + row.storeCode,
          query: {
            activityId: activityId
          }
        });
      } else {
        router.push({
          path: "/store-location/" + row.storeCode,
          query: {
            installationId: row.installationId
          }
        });
      }
    };

    //convey
    const uploadConverPane = ref(null);
    const conveyTable = ref();

    // 分页
    const conveyPaginationData = reactive({
      pageSizes: [25, 50, 100],
      currentPage: 1,
      totalSize: 0,
      pageSize: 25
    });
    // 安装进度列表
    const conveyData = reactive({
      data: []
    });
    const expressStateListData = reactive([]);
    const logisticsTypeData = reactive([]);
    const uploadConverData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const uploadConverPopoverVisible = ref(false);
    const conveyQuery = reactive({
      typeIndex: 1,
      insValue: "",
      insSelect: {
        storeType: null,
        vendorId: null,
        logisticsTypeId: null,
        state: null,
        areaInput: ""
      }
    });
    //分页Select变化
    const paginationSizeConverChange = val => {
      let data = {
        searchKeyword: conveyQuery.insValue,
        activityId: conveyQuery.insSelect.activityId,
        storeTypeId: conveyQuery.insSelect.storeType,
        vendorId: conveyQuery.insSelect.vendorId,
        city: conveyQuery.insSelect.areaInput,
        logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
        expressStateId: conveyQuery.insSelect.state
      };
      getLogisticsPageApi(data, conveyPaginationData.currentPage, conveyPaginationData.pageSize);
    };
    //分页页数变化
    const paginationCurrentConverChange = val => {
      let data = {
        searchKeyword: conveyQuery.insValue,
        activityId: conveyQuery.insSelect.activityId,
        storeTypeId: conveyQuery.insSelect.storeType,
        vendorId: conveyQuery.insSelect.vendorId,
        city: conveyQuery.insSelect.areaInput,
        logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
        expressStateId: conveyQuery.insSelect.state
      };
      getLogisticsPageApi(data, conveyPaginationData.currentPage, conveyPaginationData.pageSize);
    };

    //btn点击事件
    const openUploadConverPopover = () => {
      uploadConverPane.value.clearFiles();
      uploadConverPopoverVisible.value = true;
    };
    //当超出限制时，执行的钩子函数
    const handleConverExceed = files => {
      uploadConverPane.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadConverPane.value.handleStart(file);
      uploadConverPane.value.submit();
    };

    //上传文件之前的钩子
    const handleConverBeforeUpload = async file => {
      uploadConverData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadConverData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadConverData.signatureData.dir + fullName;
      uploadConverData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadConverData.signatureData.policy,
        OSSAccessKeyId: uploadConverData.signatureData.accessId,
        signature: uploadConverData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadConverData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    //文件上传成功的钩子
    const handleConverOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        const activityUploadedRO = {
          activityId: activityId,
          uploadedRO: {
            fileName: uploadConverData.data.name,
            uploadedName: uploadConverData.data.key
          }
        };
        importConverRequest(activityUploadedRO);
      }
    };
    const importConverRequest = async activityUploadedRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "上传成功，正在导入物流表",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.scheduleRequest.importLogistics(activityUploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202261") {
            uploadConverPopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else if (res.result.dataCode === "202266") {
            uploadConverPopoverVisible.value = false;
            importMessage.importMessageVisible = true;
            importMessage.importMessages = res.result.data;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const conveyLoading = ref(false);

    //表头排序
    const headerCellConverClickEvent = ({
      column,
      triggerResizable,
      triggerSort,
      triggerFilter
    }) => {
      const $table = conveyTable.value;
      if ($table) {
        // 如果触发了列的其他功能按钮
        if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
          if (column.order === "desc") {
            $table.clearSort();
          } else if (column.order === "asc") {
            $table.sort(column.field, "desc");
          } else {
            $table.sort(column.field, "asc");
          }
        }
      }
    };
    //获取发货进度列表
    const getLogisticsPageApi = async (data, pageIndex, pageSize, add) => {
      conveyLoading.value = true;
      let logisticsPageRO = {
        data: data,
        pageIndex: pageIndex,
        pageSize: pageSize
      };
      proxy.$api.scheduleRequest.getLogisticsPage(logisticsPageRO).then(res => {
        console.log(res);
        if (res.code === "200000") {
          if (res.result.dataCode === "202250") {
            let msg = res.result.data.pageList;
            if (msg) {
              if (add) {
                conveyData.data.push(...msg);
                conveyPaginationData.totalSize = res.result.data.totalCount;
              } else {
                conveyData.data = msg;
                conveyPaginationData.totalSize = res.result.data.totalCount;
              }
            } else {
              conveyData.data.length = 0;
              conveyPaginationData.totalSize = res.result.data.totalCount;
            }
            deliveredControl.isOpenBatch = false;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        conveyLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        conveyLoading.value = false;
      });
    };

    // 获取所有物流状态
    const getExpressState = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.scheduleRequest.getExpressState(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 获取所有确认状态
    const getLogisticsType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.scheduleRequest.getLogisticsType(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    //搜索点击事件
    const searchConverClick = () => {
      let data = {
        searchKeyword: conveyQuery.insValue,
        activityId: conveyQuery.insSelect.activityId,
        storeTypeId: conveyQuery.insSelect.storeType,
        vendorId: conveyQuery.insSelect.vendorId,
        city: conveyQuery.insSelect.areaInput,
        logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
        expressStateId: conveyQuery.insSelect.state
      };
      getLogisticsPageApi(data, 1, conveyPaginationData.pageSize);
    };

    //导出
    const openExportEvent = () => {
      let logisticsPageRO = {
        searchKeyword: conveyQuery.insValue,
        activityId: conveyQuery.insSelect.activityId,
        storeTypeId: conveyQuery.insSelect.storeType,
        vendorId: conveyQuery.insSelect.vendorId,
        city: conveyQuery.insSelect.areaInput,
        logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
        expressStateId: conveyQuery.insSelect.state
      };
      getLogisticsListExport(logisticsPageRO);
    };
    const getLogisticsListExport = logisticsPageRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "正在导出模板",
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.scheduleRequest.getLogisticsListExport(logisticsPageRO).then(res => {
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const conveyTableScroll = ({
      type,
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      bodyWidth,
      bodyHeight,
      isX,
      isY,
      $event
    }) => {
      let mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches) {
        if (scrollTop + bodyHeight >= scrollHeight - 20) {
          let totalCount = Math.ceil(conveyPaginationData.totalSize / conveyPaginationData.pageSize);
          console.log("-------------------" + conveyPaginationData.currentPage < totalCount);
          if (conveyPaginationData.currentPage < totalCount) {
            console.log("翻页");
            conveyPaginationData.currentPage++;
            let data = {
              searchKeyword: conveyQuery.insValue,
              activityId: conveyQuery.insSelect.activityId,
              storeTypeId: conveyQuery.insSelect.storeType,
              vendorId: conveyQuery.insSelect.vendorId,
              city: conveyQuery.insSelect.areaInput,
              logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
              expressStateId: conveyQuery.insSelect.state
            };
            getLogisticsPageApi(data, conveyPaginationData.currentPage, conveyPaginationData.pageSize, "add");
          }
        }
      }
    };
    const batchDeliveredLogistics = () => {
      if (deliveredControl.isOpenBatch == false) {
        deliveredControl.isOpenBatch = true;
      } else {
        const $table = conveyTable.value;
        if ($table) {
          const selectRecords = $table.getCheckboxRecords();
          let checkRecords = [];
          for (let logistics of selectRecords) {
            if (logistics.expressStateName !== "已签收" && logistics.logisticsTypeId !== 1) {
              checkRecords.push(logistics);
            }
          }
          if (checkRecords.length > 0) {
            ElMessageBox.confirm("请确认是否已送达", "询问", {
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "warning"
            }).then(() => {
              batchDeliveredLogisticsRequest(checkRecords);
            }).catch(() => {
              ElMessage({
                type: "info",
                message: "取消删除"
              });
            });
          } else {
            if ($table) {
              $table.clearCheckboxRow();
            }
            deliveredControl.isOpenBatch = false;
          }
        }
      }
    };
    const deliveredLogistics = row => {
      ElMessageBox.confirm("请确认是否已送达", "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        deliveredLogisticsRequest(row);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消删除"
        });
      });
    };
    const deliveredLogisticsRequest = async row => {
      proxy.$api.scheduleRequest.deliveredLogistics(row.logisticsId).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202264") {
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            row.expressStateName = res.result.data.expressStateName;
            row.expressStateId = res.result.data.expressStateListData;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const batchDeliveredLogisticsRequest = async rows => {
      let logisticsIds = [];
      for (let convery of rows) {
        logisticsIds.push(convery.logisticsId);
      }
      proxy.$api.scheduleRequest.batchDeliveredLogistics(logisticsIds).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202264") {
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            for (let convery of rows) {
              convery.expressStateName = "已签收";
              convery.expressStateId = "3";
            }
            const $table = conveyTable.value;
            if ($table) {
              $table.clearCheckboxRow();
            }
            deliveredControl.isOpenBatch = false;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const checkboxConfig = reactive({
      visibleMethod({
        row
      }) {
        return row.logisticsTypeId != 1 && row.expressStateName != "已签收";
      }
    });
    return {
      installConveyData,
      importMessage,
      tokenData,
      activeName,
      permission,
      router,
      installQuery,
      activityData,
      installTable,
      installPaginationData,
      uploadInstallPane,
      uploadInstallPopoverVisible,
      uploadInstallData,
      installLoading,
      cityData,
      storeTypeData,
      vendorData,
      vendorTypeData,
      installationStatusData,
      getInstallationConfirmData,
      paginationSizeInstallChange,
      paginationCurrentInstallChange,
      openUploadInstallPopover,
      handleInstallExceed,
      handleInstallBeforeUpload,
      handleInstallOnSuccess,
      getDate,
      headerCellInstallClickEvent,
      searchInstallClick,
      copyStore,
      copyVendor,
      copyResult,
      installTableScroll,
      expandConfig,
      installUploadClick,
      goConveyPlan,
      activityId,
      vendorId,
      conveyQuery,
      conveyData,
      conveyTable,
      conveyPaginationData,
      uploadConverPane,
      uploadConverPopoverVisible,
      uploadConverData,
      conveyLoading,
      expressStateListData,
      logisticsTypeData,
      paginationSizeConverChange,
      paginationCurrentConverChange,
      openUploadConverPopover,
      handleConverExceed,
      handleConverBeforeUpload,
      handleConverOnSuccess,
      headerCellConverClickEvent,
      searchConverClick,
      openExportEvent,
      conveyTableScroll,
      deliveredLogistics,
      installationData,
      openInstallationEvent,
      batchDeliveredLogistics,
      deliveredControl,
      checkboxConfig
    };
  }
};