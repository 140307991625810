import "core-js/modules/es.array.push.js";
import { reactive, ref, getCurrentInstance, onMounted, watch, nextTick } from "vue";
import { VXETable } from "vxe-table";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default {
  name: "Test",
  components: {},
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const xTableColumn = ref();
    const columnData = reactive([]);
    const statsName = ref(null);
    const itemName = ref(null);
    const selectActivityStatsItemId = ref(null);
    const activityStatsList = reactive([]);
    const xTableActivityParticipantInfo = ref(null);
    const activityData = reactive({
      activityStatsItemData: [],
      activityColumnList: [],
      formHeaderList: [],
      formBodyList: [],
      editTableHeard: {},
      editTableHeard2: {},
      editTableHeard3: {}
    });
    const pageVO1 = reactive({
      currentPage: 1,
      pageSize: 10,
      total: 100
    });
    const pageRes = () => {
      pageVO1.currentPage = 1;
      pageVO1.pageSize = 10;
      pageVO1.total = 100;
    };
    const activityStatsItemData = reactive([]);
    const activityColumnList = reactive([]);
    const rowKey = ref(null);
    const operation = ref(false);
    const showEdit = ref(false);
    const showEdit2 = ref(false);
    const val1 = ref('');
    const val2 = ref('');
    const typeList = ref([{
      label: "varchar",
      value: "varchar"
    }, {
      label: "text",
      value: "text"
    }, {
      label: "bigint",
      value: "bigint"
    }, {
      label: "int",
      value: "int"
    }, {
      label: "double",
      value: "double"
    }, {
      label: "float",
      value: "float"
    }, {
      label: "decimal",
      value: "decimal"
    }, {
      label: "date",
      value: "date"
    }, {
      label: "datetime",
      value: "datetime"
    }]);
    onMounted(async () => {
      getActivityStats();
      let currentDate = new Date();
      let lastMonthDate = new Date();
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      let formattedCurrentDate = formatDate(currentDate);
      let formattedLastMonthDate = formatDate(lastMonthDate);
      val1.value = formattedLastMonthDate;
      val2.value = formattedCurrentDate;
      watch(selectActivityStatsItemId, async (newValue, oldValue) => {
        console.log(`count changed from ${oldValue} to ${newValue}`);
        if (newValue != null) {
          pageRes();
          let currentDate = new Date();
          let lastMonthDate = new Date();
          lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
          let formattedCurrentDate = formatDate(currentDate);
          let formattedLastMonthDate = formatDate(lastMonthDate);
          val1.value = formattedLastMonthDate;
          val2.value = formattedCurrentDate;
          getActivityRes(newValue, pageVO1.currentPage, pageVO1.pageSize);
        } else {
          activityData.activityStatsItemData.length = 0;
          activityData.activityColumnList.length = 0;
          await nextTick();
          await nextTick();
          insertEvent();
        }
      }, {
        immediate: true
      });
    });
    const dataQuery = () => {
      getActivityRes(selectActivityStatsItemId.value, pageVO1.currentPage, pageVO1.pageSize);
    };
    const formatDate = date => {
      let year = date.getFullYear();
      let month = padZero(date.getMonth() + 1);
      let day = padZero(date.getDate());
      return `${year}-${month}-${day}`;
    };
    const padZero = num => {
      return num < 10 ? `0${num}` : num;
    };
    const requiredList = ref([{
      label: "Yes",
      value: true
    }, {
      label: "No",
      value: false
    }]);
    const fromRequiredLabel = value => {
      if (value === true) {
        return "Yes";
      }
      if (value === false) {
        return "No";
      }
      return "";
    };
    const insertEvent = async row => {
      const $table = xTableColumn.value;
      if ($table) {
        const {
          row: newRow
        } = await $table.insertAt(row);
        rowKey.value = newRow._X_ROW_KEY;
        await $table.setEditCell(newRow, "title");
      }
    };
    const removeEvent = async row => {
      const type = await VXETable.modal.confirm("Are you sure you want to delete this data?");
      if (type === "confirm") {
        const $table = xTableColumn.value;
        if ($table) {
          $table.remove(row);
        }
      }
    };
    // 删除此行
    const removeEvent2 = async (row, id) => {
      const type = await VXETable.modal.confirm("Are you sure you want to delete this data?");
      if (type === "confirm") {
        console.log(row.id);
        const $table = xTableActivityParticipantInfo.value;
        if ($table) {
          if (row.id) {
            let deleteRes = await deleteRow(row.id);
            if (deleteRes.dataCode === '2300') {
              $table.remove(row);
            }
          } else {
            $table.remove(row);
          }
        }
      }
    };
    const insertInfoEvent = async row => {
      const $table = xTableActivityParticipantInfo.value;
      if ($table) {
        const {
          row: newRow
        } = await $table.insertAt(row);
        await $table.setEditCell(newRow);
        await $table.setEditCell(newRow, activityData.activityColumnList[0].columnName);
      }
    };
    // 创建活动提交
    const saveEvent = () => {
      const $table = xTableColumn.value;
      if ($table) {
        const {
          insertRecords,
          removeRecords,
          updateRecords
        } = $table.getRecordset();
        if (insertRecords.length === 0) {
          VXETable.modal.alert("At least one field must be present.");
          return;
        }
        for (let record of insertRecords) {
          if (record.title == null || record.title == "") {
            VXETable.modal.alert("The field title cannot be empty.");
            return;
          }
          if (record.name == null || record.name == "") {
            VXETable.modal.alert("The field name cannot be empty.");
            return;
          }
          if (record.type == null || record.type == "") {
            VXETable.modal.alert("The field type cannot be empty.");
            return;
          }
          if (record.type === "varchar" && (record.length == null || record.length == 0)) {
            VXETable.modal.alert("Length must be specified for varchar type.");
            return;
          }
          if (statsName.value == null || statsName.value === "") {
            VXETable.modal.alert("The campaign name cannot be empty.");
            return;
          }
          if (itemName.value == null || itemName.value === "") {
            VXETable.modal.alert("The item name cannot be empty.");
            return;
          }
        }
        let activityStatsCreate = {
          name: statsName.value,
          itemName: itemName.value,
          columns: insertRecords
        };
        createActivityStats(activityStatsCreate);
      }
    };
    // 操作活动数据提交
    const saveStatsEvent = async () => {
      const $table = xTableActivityParticipantInfo.value;
      if ($table) {
        const {
          insertRecords,
          removeRecords,
          updateRecords
        } = $table.getRecordset();
        if (insertRecords.length === 0 && updateRecords.length === 0) {
          // VXETable.modal.alert("No records to save.");
          // return;
        } else {
          // 插入
          if (insertRecords.length > 0) {
            // VXETable.modal.alert("No records to save.");
            // return;
            let data = [];
            for (let record of insertRecords) {
              let coneRecord = JSON.parse(JSON.stringify(record));
              delete coneRecord._X_ROW_KEY;
              data.push(coneRecord);
            }
            let infoDataMap = {
              itemId: selectActivityStatsItemId.value,
              data: data
            };
            let addRes = await addActivityStats(infoDataMap);
            console.log(addRes);
            if (addRes.dataCode === '2000') {
              getActivityRes(selectActivityStatsItemId.value, pageVO1.currentPage, pageVO1.pageSize);
            }
          }
          // 修改
          if (updateRecords.length > 0) {
            let data = [];
            let obj = {
              itemId: selectActivityStatsItemId.value,
              id: null,
              data: null
            };
            for (let record of updateRecords) {
              let coneRecord = JSON.parse(JSON.stringify(record));
              delete coneRecord._X_ROW_KEY;
              obj.id = coneRecord.id;
              delete coneRecord.id;
              delete coneRecord.create_id;
              delete coneRecord.create_time;
              obj.data = coneRecord;
              console.log(obj);
              let editRes = await editActivityStats(obj);
              console.log(editRes);
              if (editRes.dataCode === '2200') {
                getActivityRes(selectActivityStatsItemId.value, pageVO1.currentPage, pageVO1.pageSize);
              }
            }
          }
        }
      }
    };
    // 创建活动
    const createActivityStats = async activityStatsCreate => {
      proxy.$api.activityRequestTest.createActivityStats(activityStatsCreate).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "1000") {
            getActivityStats();
            selectActivityStatsItemId.value = res.result.data.itemId;
            ElNotification({
              title: "Success",
              message: res.result.dataMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "Failure",
              message: res.result.dataMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "Failure",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "Error",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    // 新增活动数据
    const addActivityStats = async infoDataMap => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequestTest.addActivityStats(infoDataMap).then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "2000") {
              getActivityStatsItem(infoDataMap.itemId, pageVO1.currentPage, pageVO1.pageSize);
              resolve(res.result);
              ElNotification({
                title: "Success",
                message: res.result.dataMsg,
                type: "success",
                duration: 3000
              });
            } else {
              ElNotification({
                title: "Failure",
                message: res.result.dataMsg,
                type: "error",
                duration: 3000
              });
            }
          } else {
            ElNotification({
              title: "Failure",
              message: res.msg,
              type: "error",
              duration: 3000
            });
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "Error",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 修改活动数据
    const editActivityStats = async obj => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequestTest.editActivityStats(obj).then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "2200") {
              getActivityStatsItem(obj.itemId, pageVO1.currentPage, pageVO1.pageSize);
              resolve(res.result);
              ElNotification({
                title: "Success",
                message: res.result.dataMsg,
                type: "success",
                duration: 3000
              });
            } else {
              ElNotification({
                title: "Failure",
                message: res.result.dataMsg,
                type: "error",
                duration: 3000
              });
            }
          } else {
            ElNotification({
              title: "Failure",
              message: res.msg,
              type: "error",
              duration: 3000
            });
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "Error",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取活动列表数据
    const getActivityStats = async () => {
      proxy.$api.activityRequestTest.getActivityStats().then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "1100") {
            activityStatsList.length = 0;
            if (Array.isArray(res.result.data)) {
              activityStatsList.push(...res.result.data);
            }
          } else {
            ElNotification({
              title: "Failure",
              message: res.result.dataMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "Failure",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "Error",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    //单个活动表头
    const getActivityColumn = async itemId => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequestTest.getActivityColumn(itemId).then(res => {
          if (res.code === '200000') {
            if (res.result.dataCode === '1400') {
              resolve(res.result.data);
            } else {
              ElNotification({
                title: "Failure",
                message: res.result.dataMsg,
                type: "error",
                duration: 3000
              });
            }
          } else {
            ElNotification({
              title: "Failure",
              message: res.msg,
              type: "error",
              duration: 3000
            });
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "Error",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    //单个活动数据
    const getActivityStatsItem = async (activityStatsItemId, currentPage, pageSize) => {
      let obj = {
        pageIndex: currentPage,
        pageSize: pageSize,
        data: {
          itemId: activityStatsItemId,
          beginDate: val1.value,
          endDate: val2.value
        }
      };
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequestTest.getActivityStatsItem(obj).then(res => {
          if (res.code === '200000') {
            if (res.result.dataCode === '2100') {
              resolve(res.result.data);
            } else {
              ElNotification({
                title: "Failure",
                message: res.result.dataMsg,
                type: "error",
                duration: 3000
              });
            }
          } else {
            ElNotification({
              title: "Failure",
              message: res.msg,
              type: "error",
              duration: 3000
            });
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "Error",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    //整理数据
    const getActivityRes = async (newValue, currentPage, pageSize) => {
      let arr1 = await getActivityColumn(newValue);
      let arr2 = await getActivityStatsItem(newValue, currentPage, pageSize);
      activityData.activityColumnList.length = 0;
      activityData.activityColumnList = arr1;
      activityData.activityStatsItemData.length = 0;
      console.log(arr2);
      pageVO1.total = arr2.totalCount;
      activityData.activityStatsItemData = arr2.pageList;
    };
    //删除一条数据
    const deleteRow = async id => {
      let obj = {
        itemId: selectActivityStatsItemId.value,
        id: id
      };
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequestTest.deleteRow(obj).then(res => {
          if (res.code === '200000') {
            if (res.result.dataCode === '2300') {
              resolve(res.result);
            } else {
              ElNotification({
                title: "Failure",
                message: res.result.dataMsg,
                type: "error",
                duration: 3000
              });
            }
          } else {
            ElNotification({
              title: "Failure",
              message: res.msg,
              type: "error",
              duration: 3000
            });
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "Error",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    //修改表头
    const editHeaderClick = async c => {
      operation.value = 2;
      console.log(c.field);
      activityData.activityColumnList.forEach(item => {
        if (item.name === c.field) {
          activityData.editTableHeard = item;
          showEdit.value = true;
        }
      });
      activityData.editTableHeard2 = {
        ...activityData.editTableHeard
      };
      activityData.editTableHeard2.oldName = activityData.editTableHeard.name;
    };
    //删除表头
    const deleteHeaderClick = async c => {
      operation.value = 3;
      console.log(c.field);
      activityData.activityColumnList.forEach(item => {
        if (item.name === c.field) {
          activityData.editTableHeard = item;
        }
      });
      activityData.editTableHeard2 = {
        ...activityData.editTableHeard
      };
      activityData.editTableHeard2.oldName = activityData.editTableHeard.name;
      const type = await VXETable.modal.confirm('您确定要删除吗？');
      if (type === "confirm") {
        modifyApi();
      }
    };
    //新增表头
    const addHeaderClick = async c => {
      operation.value = 1;
      showEdit2.value = true;
    };
    //发送修改
    const modifyApi = async () => {
      activityData.editTableHeard2.operation = operation.value;
      let obj = {
        itemId: selectActivityStatsItemId.value,
        columns: []
      };
      obj.columns = [];
      obj.columns.push(activityData.editTableHeard2);
      console.log(obj);
      let modifRes = await getModify(obj);
      console.log(modifRes);
      if (modifRes.dataCode === '1200') {
        showEdit.value = false;
        await getActivityRes(selectActivityStatsItemId.value, pageVO1.currentPage, pageVO1.pageSize);
      }
    };
    //发送新增
    const modifyApi2 = async () => {
      activityData.editTableHeard3.operation = operation.value;
      let obj = {
        itemId: selectActivityStatsItemId.value,
        columns: []
      };
      obj.columns = [];
      obj.columns.push(activityData.editTableHeard3);
      console.log(obj);
      let modifRes = await getModify(obj);
      console.log(modifRes);
      if (modifRes.dataCode === '1200') {
        showEdit2.value = false;
        await getActivityRes(selectActivityStatsItemId.value, pageVO1.currentPage, pageVO1.pageSize);
      }
    };
    //修改数据结构
    const getModify = async obj => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequestTest.getModify(obj).then(res => {
          if (res.code === '200000') {
            if (res.result.dataCode === '1200') {
              resolve(res.result);
            } else {
              ElNotification({
                title: "Failure",
                message: res.result.dataMsg,
                type: "error",
                duration: 3000
              });
            }
          } else {
            ElNotification({
              title: "Failure",
              message: res.msg,
              type: "error",
              duration: 3000
            });
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "Error",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    //分页变化
    const pageChangeEvent1 = () => {
      console.log(`分页事件1：第 ${pageVO1.currentPage} 页，每页  ${pageVO1.pageSize} 条`);
      getActivityRes(selectActivityStatsItemId.value, pageVO1.currentPage, pageVO1.pageSize);
    };
    const parseData = (timestamp, isDateTime) => {
      let date = new Date(timestamp);

      // 创建自定义日期格式
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月份，getMonth() 返回的月份是从0开始的
      let day = date.getDate(); // 日

      let hours = date.getHours(); // 小时
      let minutes = date.getMinutes(); // 分钟
      let seconds = date.getSeconds(); // 秒

      // 按照 "YYYY-MM-DD HH:mm:ss" 的格式构建日期时间字符串
      let dateString;
      if (isDateTime) {
        dateString = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      } else {
        dateString = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
      }
      return dateString;
    };
    return {
      xTableColumn,
      columnData,
      statsName,
      itemName,
      selectActivityStatsItemId,
      activityStatsList,
      xTableActivityParticipantInfo,
      activityData,
      activityStatsItemData,
      activityColumnList,
      rowKey,
      typeList,
      requiredList,
      showEdit,
      showEdit2,
      operation,
      pageChangeEvent1,
      pageVO1,
      fromRequiredLabel,
      insertEvent,
      removeEvent,
      removeEvent2,
      insertInfoEvent,
      saveEvent,
      saveStatsEvent,
      createActivityStats,
      addActivityStats,
      getActivityStats,
      getActivityColumn,
      getActivityStatsItem,
      getActivityRes,
      editActivityStats,
      editHeaderClick,
      deleteHeaderClick,
      modifyApi,
      modifyApi2,
      addHeaderClick,
      val1,
      val2,
      dataQuery
    };
  }
};